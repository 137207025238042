// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  hmr: false,


  // Local
  // API_URL: 'http://localhost:8000/api/admin/',
  // API_URL_LANDING: 'http://localhost:8000/api/landing/',
  // URL: 'http://localhost:8000/api/',
  // URL_LANDING: 'http://localhost:8000/',


  // QA
  // API_URL: 'https://qaback.in-mo.app/api/admin/',
  // API_URL_LANDING: 'https://qaback.in-mo.app/api/landing/',
  // URL: 'https://qaback.in-mo.app/api/',
  // URL_LANDING: 'https://qa.in-mo.app/ ', 


  // Produccion
  API_URL: 'https://back.in-mo.app/api/admin/',  
  API_URL_LANDING: 'https://back.in-mo.app/api/landing/',
  URL: 'https://back.in-mo.app/api/',
  URL_LANDING: 'https://in-mo.app/ ',

  URL_SUBDOMAIN_LANDING: '.in-mo.app',
  URL_AWS: 'https://inmoapp-storage.s3.us-west-1.amazonaws.com/',
  MAPS_API_KEY: 'AIzaSyCX2xPTQp5j8T3pla2GSzRuYTi8z_QVEME',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
