import { Injectable } from "@angular/core";
import { ServerService } from "../services/server.service";
import {
  Property,
  Amenities,
  PropertyImages,
  PropertyType,
} from "./property.model";
import { Consultant } from "../agentes/consultant.model";
import { Search } from "app/main/pages/landing/search.model";

@Injectable({
  providedIn: "root",
})
export class PropertyService {
  private property: Property[];
  private amenities: Amenities[];
  private sharingObject: Search;

  constructor(private _server: ServerService) {}

  allPropertiesToExpireRealEstate(): Promise<Property[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("allPropertiesToExpireRealEstate").then(
        (resp: any[]) => {
          this.property = resp;
          resolve(resp);
        },
        (err: any) => {
          reject();
        }
      );
    });
  }

  getPropertys(): Promise<Property[]> {
    return new Promise((resolve, reject) => {
      this.allPropertys().then(
        () => {
          const property: Property[] = JSON.parse(
            localStorage.getItem("Properties")
          );
          resolve(property);
        },
        () => {
          const property: Property[] = [];
          reject(property);
        }
      );
    });
  }

  getProperty(id: number): Promise<Property> {
    return new Promise((resolve, reject) => {
      this._server.getServer("Properties/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  getNotes(id: number): Promise<Property> {
    return new Promise((resolve, reject) => {
      this._server.getServer("getNotes/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  getAmenitiesProperty(id: number): Promise<Property> {
    return new Promise((resolve, reject) => {
      this._server.getServer("AmenitiesProperties/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  allPropertys(): Promise<Property[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("Properties").then(
        (resp: any[]) => {
          this.property = resp;
          resolve(resp);
        },
        (err: any) => {
          reject();
        }
      );
    });
  }

  getPropertiesAsAdmin(data): Promise<Property[]> {
    return new Promise((resolve, reject) => {
      this._server.postServer("getPropertiesAsAdmin", data).then(
        (resp: any[]) => {
          this.property = resp;
          resolve(resp);
        },
        (err: any) => {
          reject();
        }
      );
    });
  }

  replaceAllPropertys(params): Promise<Property[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("replaceIndex", params).then(
        (resp: any[]) => {
          this.property = resp;
          resolve(resp);
        },
        (err: any) => {
          reject();
        }
      );
    });
  }

  allPropertysRealEstate(id: number): Promise<Property[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("indexProperties/" + id).then(
        (resp: any[]) => {
          this.property = resp;
          resolve(resp);
        },
        (err: any) => {
          reject();
        }
      );
    });
  }

  allPropertysRealEstateAPI(id: number): Promise<Property[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("indexPropertiesAPI/" + id).then(
        (resp: any[]) => {
          this.property = resp;
          resolve(resp);
        },
        (err: any) => {
          reject();
        }
      );
    });
  }

  allPropertysAgent(): Promise<Property[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("indexPropertiesAgent").then(
        (resp: any[]) => {
          this.property = resp;
          resolve(resp);
        },
        (err: any) => {
          reject();
        }
      );
    });
  }

  allPropertiesTypes(): Promise<PropertyType[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("propertyTypes").then(
        (resp: PropertyType[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  getAmenities(): Promise<Amenities[]> {
    return new Promise((resolve, reject) => {
      this.allAmenities().then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getAgentsRealEstate(id: number): Promise<Consultant[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("indexAgentsRE/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (err: any) => {
          reject();
        }
      );
    });
  }

  allAmenities(): Promise<Amenities> {
    console.log("allAmenities property.service");
    return new Promise((resolve, reject) => {
      this._server.getServer("amenities").then(
        (resp: any) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  setFavoAgent(idProperty: number, idUser: number = null): Promise<any> {
    return new Promise((resolve, reject) => {
      const env = {
        propertyId: idProperty,
        agentId: idUser,
      };
      this._server.postServer("setFavorite", env).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  saveChangesPropertyDelete(dateChange): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer("saveProfilingWeb", dateChange).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  saveNoPublicityProperty(dateChange): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer("saveNoPublicityProperty", dateChange).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  saveNotificationDisable(dateChange): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer("saveNotificationDisable", dateChange).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  addProperty(property: Property): Promise<Property> {
    return new Promise((resolve, reject) => {
      this._server.postServer("Properties", property).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  ordenImages(imgs: any): Promise<Property> {
    return new Promise((resolve, reject) => {
      this._server.postServer("PropertyImagesOrder", imgs).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  updateProperty(property: Property): Promise<Property> {
    return new Promise((resolve, reject) => {
      this._server.putServer("Properties/" + property.id, property).then(
        (resp: Property) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  copyProperty(id): Promise<Property> {
    return new Promise((resolve, reject) => {
      this._server.getServer("copyProperty/" + id).then(
        (resp: Property) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  deleteProperty(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this._server.deleteServer("Properties/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  disableProperty(id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this._server.getServer("enableDisable/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  uploadFile(file: PropertyImages, id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const input = new FormData();
      this._server.postFileData("PropertyImages/" + id, file, file.image).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  deleteImageProperty(img: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this._server.postServer("PropertyImageDelete/" + img.id, img).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  realEstateSubscriptions(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("getSubscriptionsRealEstate/" + id).then(
        (resp: any[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  getPDF(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getFile("propertyInventory", params).then(
        (resp: any[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  getPDF2(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getFile2Post("propertyInventory", params).then(
        (resp: any[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  increaseValidity(datos): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer("increaseValidity", datos).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getStates(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer("states").then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getProvinceById(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("provinces/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  getSuburbsById(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer("suburbs/" + id).then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  get sharingValue() {
    this.sharingObject = JSON.parse(localStorage.getItem("busqueda"));
    return this.sharingObject;
  }

  set sharingValue(obj) {
    this.sharingObject = obj;
    localStorage.setItem("busqueda", JSON.stringify(this.sharingObject));
  }

  PropertiesSearchResults(params: any): Promise<Property[]> {
    return new Promise((resolve, reject) => {
      this._server.postServer("PropertiesWithFilterAdmin", params).then(
        (resp: Property[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }
  FilterAvancedAdmin(params: any): Promise<Property[]> {
    return new Promise((resolve, reject) => {
      this._server.postServer("filter-avanced-admin", params).then(
        (resp: Property[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  getPDFProperty(idPr: number, con_datos): Promise<any> {
    let url;
    if (con_datos) url = "sharePropertyImg/" + idPr + "/1";
    else url = "sharePropertyImg/" + idPr;
    console.log("MIURL", url);
    return new Promise((resolve, reject) => {
      this._server.getFile(url).then(
        (resp: any[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  getPDFPropertyWithoutLogin(idPr: number, con_datos): Promise<any> {
    let url;
    if (con_datos) url = "sharePropertyImgWithoutLogin/" + idPr + "/1";
    else url = "sharePropertyImgWithoutLogin/" + idPr;
    return new Promise((resolve, reject) => {
      this._server.getFileWithoutHeader(url).then(
        (resp: any[]) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  updateNote(property: Property): Promise<Property> {
    return new Promise((resolve, reject) => {
      this._server.postServer("updateNote/" + property.id, property).then(
        (resp: Property) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  // indexDropdowns
  indexDropdowns(page?: any, params_busqueda?: any, order?: any): Promise<Property> {
    return new Promise((resolve, reject) => {
      let paramstosend;
      if (page || params_busqueda || order) {
        console.log("existes?", order); /* page = {'page': page};  */
        paramstosend = Object.assign(params_busqueda, page, order);
      }
      console.log("POST paramsstosend", paramstosend);

      this._server.postServer("indexDropdowns", paramstosend).then(
        (resp: any) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  getReplicasOfProperty(property_id): Promise<Property> {
    return new Promise((resolve, reject) => {
      this._server.getServer("allPropertiesRealEstate",property_id).then(//corregir ruta
        (resp: any) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  allPropertiesRealEstate(): Promise<Property> {
    return new Promise((resolve, reject) => {
      this._server.getServer("allPropertiesRealEstate").then(
        (resp: any) => {
          resolve(resp);
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  // HACER LA RUTA PARA LA DE api/admin/
  // indexDropdowns(idUs: number = null, page?:any, params_busqueda?:any): Promise<Property[]> {
  //   return new Promise((resolve, reject) => {

  //     // const cadConexion = idUs ? 'indexWithAgent/' + idUs : 'Properties';
  //     let paramstosend;
  //     if(page || params_busqueda) {
  //       console.log('existez?', page); /* page = {'page': page};  */paramstosend = Object.assign(params_busqueda, page);
  //     }
  //     console.log('POST paramsstosend', paramstosend);

  //     this._server.postServer('filter-avanced-agent', paramstosend).then(
  //       (resp: Property[]) => {
  //         this.property = resp;
  //         resolve(resp);
  //       }, (err: any) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }
}
