import { Injectable } from '@angular/core';
import { CoreMenu } from '@core/types';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  menuP: CoreMenu[];
  currentUser;

  constructor() { }

  asignOptionMenu(mostrarEstadisticas, mostrarAdminInterna) {

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))?.user;

    if (this.currentUser) {
      const roles = this.currentUser['role'] === 'Agente' ? 'Inmobiliaria' : this.currentUser['role'];
      console.log('ROLES APP COMPONENT', roles);

      switch (roles) {
        case 'Super Administrador':
          console.log('SOY UN Super Administrador');
          let menuPermission = [];
          let permission = null;
          // permission = this.currentUser.permissions.find(permi => permi.name === 'Ver dashboard');
          // if (permission) {
            menuPermission.push({
              id: 'dashboard',
              title: 'Dashboard',
              type: 'item',
              icon: 'trending-up',
              url: '/dashboard'
            });
          // }
          let child = []
          
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver inmobiliarias');
          if (permission) {
            menuPermission.push({
              id: 'inmobiliarias',
              title: 'Inmobiliarias',
              type: 'item',
              icon: 'book-open',
              url: '/inmobiliarias'
            });
          }
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver inmuebles');
          if (permission) {
            menuPermission.push({
              id: 'inmuebles',
              title: 'Inmuebles',
              type: 'item',
              icon: 'home',
              url: '/properties'
            });
          } */
          // permission = this.currentUser.permissions.find(permi => permi.name === 'Ver asesores');
          // if (permission) {
          //   menuPermission.push({
          //     id: 'asesores',
          //     title: 'Asesores',
          //     type: 'item',
          //     icon: 'briefcase',
          //     url: 'asesores/'
          //   });
          // }
          /* permission = true;//this.currentUser.permissions.find(permi => permi.name === 'Editar suscripción inmobiliaria');
          if (permission) {
            menuPermission.push({
              id: 'propietarios',
              title: 'Propietarios',
              type: 'item',
              icon: 'dollar-sign',
              url: '/propietarios'
            });
          } */
          child = [];
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver clientes');
          if (permission) {
            child.push({
              id: 'leads',
              title: 'Clientes',
              type: 'item',
              icon: 'circle',
              url: '/clientes/clients'
            });
          }
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver clientes');
          if (permission) {
            child.push({
              id: 'leads',
              title: 'Leads',
              type: 'item',
              icon: 'circle',
              url: '/clientes/leads'
            });
          }
          // permission = this.currentUser.permissions.find(permi => permi.name === 'Ver propietarios');
          // if(this.currentUser.admin) permission = true;
          // if (permission) {
            child.push({
              id: 'propietarios',
              title: 'Propietarios',
              type: 'item',
              icon: 'circle',
              url: '/propietarios'
            });
          // }
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver estadísticas de ventas');
          if (permission) {
            child.push({
              id: 'statistics',
              title: 'Estadísticas',
              type: 'item',
              icon: 'circle',
              url: '/clientes/statistics'
            });
          } */
         permission = this.currentUser.permissions.find(permi => permi.name === 'Ver administración interna');
          if (permission) {
            child.push({
              id: 'adminInternal',
              title: 'Administración Interna',
              type: 'item',
              icon: 'circle',
              url: '/clientes/adminInternal'
            });
          } 
          if (child.length != 0) {
            menuPermission.push({
              id: 'clientes',
              title: 'Clientes',
              type: 'collapsible',
              icon: 'smile',
              children: child
            });
          }

          permission = this.currentUser.permissions.find(permi => permi.name === 'Editar suscripción inmobiliaria');
          if (permission) {
            menuPermission.push({
              id: 'subscription',
              title: 'Suscripciones',
              type: 'item',
              icon: 'dollar-sign',
              url: '/subscriptions'
            });
          }
          if (this.currentUser.role == 'Super Administrador') {
            menuPermission.push({
              id: 'maunal-payments',
              title: 'Pagos Manuales',
              type: 'item',
              icon: 'dollar-sign',
              url: '/manual-subscriptions'
            });
          }
          // permission = this.currentUser.permissions.find(permi => permi.name === 'Editar suscripción inmobiliaria');
          // if (permission) {
            // menuPermission.push({
            //   id: 'complements',
            //   title: 'Complementos',
            //   type: 'item',
            //   icon: 'box',
            //   url: '/complements'
            // });
          // }

          /* // permission = this.currentUser.permissions.find(permi => permi.name === 'Editar suscripción inmobiliaria');
          if (this.currentUser.role == 'Super Administrador') {
            menuPermission.push({
              id: 'products',
              title: 'Productos',
              type: 'item',
              icon: 'dollar-sign',
              url: '/products'
            });
          } */
          // if (this.currentUser.role == 'Super Administrador') {
          //   menuPermission.push({
          //     id: 'payments',
          //     title: 'Pagos Inscripción',
          //     type: 'item',
          //     icon: 'dollar-sign',
          //     url: '/payments'
          //   });
          // }
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver notificaciones');
          if (permission) {
            menuPermission.push({
              id: 'notification',
              title: 'Notificaciones',
              type: 'item',
              icon: 'bell',
              url: '/notifications'
            });
          } */
          child = [];
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Agregar cuentas de redes sociales');
          if (permission) {
            child.push({
              id: 'redes',
              title: 'Redes Sociales',
              type: 'item',
              icon: 'circle',
              url: 'marketing/redessociales'
            });
          } */
          permission = this.currentUser.permissions.find(permi => permi.name === 'Agregar cuentas de redes sociales');
          if (permission) {
            child.push({
              id: 'publicity',
              title: 'Replicas',
              type: 'item',
              icon: 'circle',
              url: 'marketing/publicidad'
            })
          }
          /* if (child.length != 0) {
            menuPermission.push({
              id: 'marketing',
              title: 'Marketing',
              type: 'collapsible',
              icon: 'shopping-bag',
              children: child
            });
          } */

          child = [];
          permission = this.currentUser.permissions.find(permi => permi.name === 'Realizar valoración inmueble');
          if (permission) {
            child.push({
              id: 'valuacion',
              title: 'Valuación',
              type: 'item',
              icon: 'circle',
              url: 'utilidades/valoracioninmueble'
            });
          }
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver directorio');
          if (permission) {
            child.push({
              id: 'directorio',
              title: 'Directorio',
              type: 'item',
              icon: 'circle',
              url: '/directorio'
            });
          }
          permission = this.currentUser.permissions.find(permi => permi.name === 'Realizar valoración hipoteca');
          if (permission) {
            child.push({
              id: 'hipoteca',
              title: 'Hipoteca',
              type: 'item',
              icon: 'circle',
              url: 'utilidades/valoracionhipoteca'
            });
          }

          /* if (child.length != 0) {
            menuPermission.push({
              id: 'utilidades',
              title: 'Utilidades',
              type: 'collapsible',
              icon: 'box',
              children: child
            });
          } */

          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver FAQS');
          if (permission) {
            let child = [
              {
                id: 'faqs',
                title: 'FAQs',
                type: 'item',
                icon: 'help-circle',
                url: '/settings/faqs'
              }];
            menuPermission.push({
              id: 'settings',
              title: 'Configuraciones',
              type: 'collapsible',
              icon: 'settings',
              children: child
            });
          }

          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver asociaciones');
          if (permission) {
            menuPermission.push({
              id: 'asociaciones',
              title: 'Asociaciones',
              type: 'item',
              icon: 'book',
              url: '/asociaciones'
            });
          } */
          /* let childUser = [];
          childUser.push({
            id: 'miPerfil',
            title: 'Cuenta',
            type: 'item',
            icon: 'circle',
            url: 'usuarios/perfil'
          });
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver Mi Inmobiliaria');
          if (permission) {
            childUser.push({
              id: 'miInmobiliaria',
              title: 'Mi Inmobiliaria',
              type: 'item',
              icon: 'circle',
              url: 'usuarios/miInmobiliaria'
            });
          }
          childUser.push({
            id: 'tarjetaDigital',
            title: 'Tarjeta Digital',
            type: 'item',
            icon: 'circle',
            url: 'usuarios/tarjetaDigital'
          });
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver Página Web');
          if (permission) {
            childUser.push({
              id: 'webSite',
              title: 'Página Web',
              type: 'item',
              icon: 'circle',
              url: 'usuarios/webSite'
            });
          }

          menuPermission.push({
            id: 'perfil',
            title: 'Perfil',
            type: 'collapsible',
            icon: 'user',
            children: childUser
          }); */
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver usuarios');
          if (permission) {
            menuPermission.push({
              id: 'usuarios',
              title: 'Usuarios',
              type: 'item',
              icon: 'users',
              url: 'usuarios/usuarios'
            });
          }
          if (this.currentUser.role == 'Super Administrador') {
            menuPermission.push({
              id: 'roles',
              title: 'Roles',
              type: 'item',
              icon: 'users',
              url: 'usuarios/roles'
            });
          }
          if (this.currentUser.role == 'Super Administrador') {
            menuPermission.push({
              id: 'usuarios_admin',
              title: 'Usuarios admin',
              type: 'item',
              icon: 'users',
              url: 'usuarios/admin'
            });
          }
          this.menuP = menuPermission
          break;
        case 'Inmobiliaria':
          console.log('SOY UNA Inmobiliaria');
          let menuPermissionInmo = [];
          let permissionInmo = null;
          // permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver dashboard');
          // if (permissionInmo) {
            menuPermissionInmo.push({
              id: 'dashboard',
              title: 'Dashboard',
              type: 'item',
              icon: 'trending-up',
              url: '/dashboard'
            });
          // }
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver inmuebles');
          if (permissionInmo) {
            menuPermissionInmo.push({
              id: 'inmuebles',
              title: 'Inmuebles',
              type: 'item',
              icon: 'home',
              url: '/properties'
            });
          }
          /* permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver inmuebles');
          if (permissionInmo) {
            menuPermissionInmo.push({
              id: 'share-properties',
              title: 'Inmuebles Compartidos',
              type: 'item',
              icon: 'home',
              url: '/share-properties'
            });
          } */
          // permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver asesores');
          // if (permissionInmo) {
          //   menuPermissionInmo.push({
          //     id: 'asesores',
          //     title: 'Asesores',
          //     type: 'item',
          //     icon: 'briefcase',
          //     url: 'asesores/'
          //   });
          // }
          /* permissionInmo = true;//this.currentUser.permissions.find(permi => permi.name === 'Ver inmuebles');
          if (permissionInmo) {
            menuPermissionInmo.push({
              id: 'propietarios',
              title: 'Propietarios',
              type: 'item',
              icon: 'users',
              url: '/propietarios'
            });
          } */
          let childInmo = [];
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver clientes');
          if (permissionInmo) {
            childInmo.push({
              id: 'clientes',
              title: 'Clientes',
              type: 'item',
              icon: 'circle',
              url: '/clientes/clients'
            });
          }
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver clientes');
          if (permissionInmo) {
            childInmo.push({
              id: 'leads',
              title: 'Leads',
              type: 'item',
              icon: 'circle',
              url: '/clientes/leads'
            });
          }
          // permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver propietarios');
          if(this.currentUser.admin || this.currentUser.permissions.find(permi => permi.name === 'Ver propietarios')) permissionInmo = true;
          if (permissionInmo) {
            childInmo.push({
              id: 'propietarios',
              title: 'Propietarios',
              type: 'item',
              icon: 'circle',
              url: '/propietarios'
            });
          }
          /* permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver estadísticas de ventas');
          if (mostrarEstadisticas) {
            if (permissionInmo) {
              childInmo.push({
                id: 'statistics',
                title: 'Estadísticas',
                type: 'item',
                icon: 'circle',
                url: '/clientes/statistics'
              });
            }
          } */
         
          if (childInmo.length != 0) {
            menuPermissionInmo.push({
              id: 'clientes',
              title: 'Clientes',
              type: 'collapsible',
              icon: 'smile',
              children: childInmo
            });
          }

          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Editar suscripción inmobiliaria');
          if (permissionInmo) {
            menuPermissionInmo.push({
              id: 'subscription',
              title: 'Suscripciones',
              type: 'item',
              icon: 'dollar-sign',
              url: '/subscriptions'
            });
          }
          // permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Editar suscripción inmobiliaria');
          if (this.currentUser.role == 'Super Administrador') {
            menuPermissionInmo.push({
              id: 'products',
              title: 'Productos',
              type: 'item',
              icon: 'dollar-sign',
              url: '/products'
            });
          }
          if (this.currentUser.role == 'Super Administrador') {
            menuPermissionInmo.push({
              id: 'payments',
              title: 'Pagos Inscripción',
              type: 'item',
              icon: 'dollar-sign',
              url: '/payments'
            });
          }
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver notificaciones');
          if (permissionInmo) {
            menuPermissionInmo.push({
              id: 'notification',
              title: 'Notificaciones',
              type: 'item',
              icon: 'bell',
              url: '/notifications'
            });
          }

          childInmo = [];
          /* permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Agregar cuentas de redes sociales');
          if (permissionInmo) {
            childInmo.push({
              id: 'redes',
              title: 'Redes Sociales',
              type: 'item',
              icon: 'circle',
              url: 'marketing/redessociales'
            });
          } */
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Agregar cuentas de redes sociales');
          if (permissionInmo) {
            childInmo.push({
              id: 'publicity',
              title: 'Replicas',
              type: 'item',
              icon: 'circle',
              url: 'marketing/publicidad'
            })
          }
          if (childInmo.length != 0) {
            menuPermissionInmo.push({
              id: 'marketing',
              title: 'Marketing',
              type: 'collapsible',
              icon: 'shopping-bag',
              children: childInmo
            });
          }
          childInmo = [];
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Realizar valoración inmueble');
          if (permissionInmo) {
            childInmo.push({
              id: 'valuacion',
              title: 'Valuación',
              type: 'item',
              icon: 'circle',
              url: 'utilidades/valoracioninmueble'
            });
          }
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver directorio');
          if (permissionInmo) {
            childInmo.push({
              id: 'directorio',
              title: 'Directorio',
              type: 'item',
              icon: 'circle',
              url: '/directorio'
            });
          }
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Realizar valoración hipoteca');
          if (permissionInmo) {
            childInmo.push({
              id: 'hipoteca',
              title: 'Hipoteca',
              type: 'item',
              icon: 'circle',
              url: 'utilidades/valoracionhipoteca'
            });
          }

          /* if (childInmo.length != 0) {
            menuPermissionInmo.push({
              id: 'utilidades',
              title: 'Utilidades',
              type: 'collapsible',
              icon: 'box',
              children: childInmo
            });
          } */
          let childUserInmo = [];
          childUserInmo.push({
            id: 'miPerfil',
            title: 'Cuenta',
            type: 'item',
            icon: 'circle',
            url: 'usuarios/perfil'
          });
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver Mi Inmobiliaria');
          if (permissionInmo) {
            childUserInmo.push({
              id: 'miInmobiliaria',
              title: 'Mi Inmobiliaria',
              type: 'item',
              icon: 'circle',
              url: 'usuarios/miInmobiliaria'
            });
          }
          let permisoTarjeta = this.currentUser.permissions.find(permi => permi.name === 'Tarjeta digital') 
          if(permisoTarjeta || this.currentUser.admin){
            childUserInmo.push({
              id: 'tarjetaDigital',
              title: 'Tarjeta Digital',
              type: 'item',
              icon: 'circle',
              url: 'usuarios/tarjetaDigital'
            });
          }
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver Página Web');
          if (permissionInmo) {
            childUserInmo.push({
              id: 'webSite',
              title: 'Página Web',
              type: 'item',
              icon: 'circle',
              url: 'usuarios/webSite'
            });
          }
          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver administración interna');
          if (mostrarAdminInterna) {
            if (permissionInmo) {
              childUserInmo.push({
                id: 'adminInternal',
                title: 'Administración Interna',
                type: 'item',
                icon: 'circle',
                url: '/clientes/adminInternal'
              });
            }
          } 

          menuPermissionInmo.push({
            id: 'perfil',
            title: 'Perfil',
            type: 'collapsible',
            icon: 'user',
            children: childUserInmo
          });

          permissionInmo = this.currentUser.permissions.find(permi => permi.name === 'Ver usuarios');
          if (permissionInmo) {
            menuPermissionInmo.push({
              id: 'usuarios',
              title: 'Usuarios',
              type: 'item',
              icon: 'users',
              url: 'usuarios/usuarios'
            });
          }
          if (this.currentUser.role == 'Super Administrador') {
            menuPermissionInmo.push({
              id: 'roles',
              title: 'Roles',
              type: 'item',
              icon: 'users',
              url: 'usuarios/roles'
            });
          }
          if (this.currentUser.role == 'Super Administrador') {
            menuPermissionInmo.push({
              id: 'usuarios_admin',
              title: 'Usuarios admin',
              type: 'item',
              icon: 'users',
              url: 'usuarios/admin'
            });
          }
          this.menuP = menuPermissionInmo
          break;
        case 'Director':
          console.log('SOY UN Director');
          let menuPermissionDirec = [];
          // let permissionDirec = null;
          
            menuPermissionDirec.push({
              id: 'dashboard',
              title: 'Dashboard',
              type: 'item',
              icon: 'trending-up',
              url: '/dashboard'
            });
            menuPermissionDirec.push({
              id: 'inmobiliarias',
              title: 'Inmobiliarias',
              type: 'item',
              icon: 'book-open',
              url: '/inmobiliarias'
            });
          // permission = this.currentUser.permissions.find(permi => permi.name === 'Ver asesores');
          // if (permission) {
          //   menuPermission.push({
          //     id: 'asesores',
          //     title: 'Asesores',
          //     type: 'item',
          //     icon: 'briefcase',
          //     url: 'asesores/'
          //   });
          // }
          // let childDirec = [];
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Editar suscripción inmobiliaria');
          if (permission) {
            menuPermission.push({
              id: 'subscription',
              title: 'Suscripciones',
              type: 'item',
              icon: 'dollar-sign',
              url: '/subscriptions'
            });
          } */
          /* // permission = this.currentUser.permissions.find(permi => permi.name === 'Editar suscripción inmobiliaria');
          if (this.currentUser.role == 'Super Administrador') {
            menuPermission.push({
              id: 'products',
              title: 'Productos',
              type: 'item',
              icon: 'dollar-sign',
              url: '/products'
            });
          } */
          let childDirec = [];
          childDirec.push({
            id: 'ganancias',
            title: 'Ganancias',
            type: 'item',
            icon: 'circle',
            url: '/finanzas/ganancias'
          });
          if(childDirec.length != 0) {
            menuPermissionDirec.push({
              id: 'finanzas',
              title: 'Finanzas',
              type: 'collapsible',
              icon: 'dollar-sign',
              children: childDirec
            });
          }
            menuPermissionDirec.push({
              id: 'payments',
              title: 'Pagos Inscripción',
              type: 'item',
              icon: 'dollar-sign',
              url: '/payments'
            });
            childDirec = []
            childDirec.push({
                id: 'faqs',
                title: 'FAQs',
                type: 'item',
                icon: 'help-circle',
                url: '/settings/faqs'
              });
            menuPermissionDirec.push({
              id: 'settings',
              title: 'Configuraciones',
              type: 'collapsible',
              icon: 'settings',
              children: childDirec
            });
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver asociaciones');
          if (permission) {
            menuPermission.push({
              id: 'asociaciones',
              title: 'Asociaciones',
              type: 'item',
              icon: 'book',
              url: '/asociaciones'
            });
          } */
          /* let childUser = [];
          childUser.push({
            id: 'miPerfil',
            title: 'Cuenta',
            type: 'item',
            icon: 'circle',
            url: 'usuarios/perfil'
          });
          menuPermission.push({
            id: 'perfil',
            title: 'Perfil',
            type: 'collapsible',
            icon: 'user',
            children: childUser
          }); */
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver usuarios');
          if (permission) {
            menuPermission.push({
              id: 'usuarios',
              title: 'Usuarios',
              type: 'item',
              icon: 'users',
              url: 'usuarios/usuarios'
            });
          } */
            menuPermissionDirec.push({
              id: 'roles',
              title: 'Roles',
              type: 'item',
              icon: 'users',
              url: 'usuarios/roles'
            });
            menuPermissionDirec.push({
              id: 'usuarios_admin',
              title: 'Usuarios admin',
              type: 'item',
              icon: 'users',
              url: 'usuarios/admin'
            });
          this.menuP = menuPermissionDirec
        break;
        case 'Coordinador':
          console.log('SOY UN Coordinador');
          let menuPermissionCoord = [];
          // let permissionCoord = null;
            menuPermissionCoord.push({
              id: 'dashboard',
              title: 'Dashboard',
              type: 'item',
              icon: 'trending-up',
              url: '/dashboard'
            });
            menuPermissionCoord.push({
              id: 'inmobiliarias',
              title: 'Inmobiliarias',
              type: 'item',
              icon: 'book-open',
              url: '/inmobiliarias'
            });
            let childCoord = [];
          childCoord.push({
            id: 'ganancias',
            title: 'Ganancias',
            type: 'item',
            icon: 'circle',
            url: '/finanzas/ganancias'
          });
          if(childCoord.length != 0) {
            menuPermissionCoord.push({
              id: 'finanzas',
              title: 'Finanzas',
              type: 'collapsible',
              icon: 'dollar-sign',
              children: childCoord
            });
          }
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver inmuebles');
          if (permission) {
            menuPermission.push({
              id: 'inmuebles',
              title: 'Inmuebles',
              type: 'item',
              icon: 'home',
              url: '/properties'
            });
          } */
          // permission = this.currentUser.permissions.find(permi => permi.name === 'Ver asesores');
          // if (permission) {
          //   menuPermission.push({
          //     id: 'asesores',
          //     title: 'Asesores',
          //     type: 'item',
          //     icon: 'briefcase',
          //     url: 'asesores/'
          //   });
          // }
          childCoord = [];
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver clientes');
          if (permission) {
            child.push({
              id: 'leads',
              title: 'Clientes',
              type: 'item',
              icon: 'circle',
              url: '/clientes/clients'
            });
          }
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver clientes');
          if (permission) {
            child.push({
              id: 'leads',
              title: 'Leads',
              type: 'item',
              icon: 'circle',
              url: '/clientes/leads'
            });
          } */
            /* childCoord.push({
              id: 'statistics',
              title: 'Estadísticas',
              type: 'item',
              icon: 'circle',
              url: '/clientes/statistics'
            });
            childCoord.push({
              id: 'adminInternal',
              title: 'Administración Interna',
              type: 'item',
              icon: 'circle',
              url: '/clientes/adminInternal'
            }); */
          /*
          if (child.length != 0) {
            menuPermission.push({
              id: 'clientes',
              title: 'Clientes',
              type: 'collapsible',
              icon: 'smile',
              children: child
            });
          } */

          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Editar suscripción inmobiliaria');
          if (permission) {
            menuPermission.push({
              id: 'subscription',
              title: 'Suscripciones',
              type: 'item',
              icon: 'dollar-sign',
              url: '/subscriptions'
            });
          } */
          /* // permission = this.currentUser.permissions.find(permi => permi.name === 'Editar suscripción inmobiliaria');
          if (this.currentUser.role == 'Super Administrador') {
            menuPermission.push({
              id: 'products',
              title: 'Productos',
              type: 'item',
              icon: 'dollar-sign',
              url: '/products'
            });
          } */
            menuPermissionCoord.push({
              id: 'payments',
              title: 'Pagos Inscripción',
              type: 'item',
              icon: 'dollar-sign',
              url: '/payments'
            });
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver notificaciones');
          if (permission) {
            menuPermission.push({
              id: 'notification',
              title: 'Notificaciones',
              type: 'item',
              icon: 'bell',
              url: '/notifications'
            });
          } */
          childCoord = [];
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Agregar cuentas de redes sociales');
          if (permission) {
            child.push({
              id: 'redes',
              title: 'Redes Sociales',
              type: 'item',
              icon: 'circle',
              url: 'marketing/redessociales'
            });
          } */
            /* child.push({
              id: 'publicity',
              title: 'Replicas',
              type: 'item',
              icon: 'circle',
              url: 'marketing/publicidad'
            }) */
          /* if (child.length != 0) {
            menuPermission.push({
              id: 'marketing',
              title: 'Marketing',
              type: 'collapsible',
              icon: 'shopping-bag',
              children: child
            });
          } */

          childCoord = [];
            childCoord.push({
              id: 'valuacion',
              title: 'Valuación',
              type: 'item',
              icon: 'circle',
              url: 'utilidades/valoracioninmueble'
            });
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver directorio');
          if (permission) {
            child.push({
              id: 'directorio',
              title: 'Directorio',
              type: 'item',
              icon: 'circle',
              url: '/directorio'
            });
          } */
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Realizar valoración hipoteca');
          if (permission) {
            child.push({
              id: 'hipoteca',
              title: 'Hipoteca',
              type: 'item',
              icon: 'circle',
              url: 'utilidades/valoracionhipoteca'
            });
          } */

          if (childCoord.length != 0) {
            menuPermissionCoord.push({
              id: 'utilidades',
              title: 'Utilidades',
              type: 'collapsible',
              icon: 'box',
              children: childCoord
            });
          }

            childCoord = [
              {
                id: 'faqs',
                title: 'FAQs',
                type: 'item',
                icon: 'help-circle',
                url: '/settings/faqs'
              }];
            menuPermissionCoord.push({
              id: 'settings',
              title: 'Configuraciones',
              type: 'collapsible',
              icon: 'settings',
              children: childCoord
            });

          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver asociaciones');
          if (permission) {
            menuPermission.push({
              id: 'asociaciones',
              title: 'Asociaciones',
              type: 'item',
              icon: 'book',
              url: '/asociaciones'
            });
          } */
          /* let childUser = [];
          childUser.push({
            id: 'miPerfil',
            title: 'Cuenta',
            type: 'item',
            icon: 'circle',
            url: 'usuarios/perfil'
          });

          menuPermission.push({
            id: 'perfil',
            title: 'Perfil',
            type: 'collapsible',
            icon: 'user',
            children: childUser
          }); */
            /* menuPermission.push({
              id: 'usuarios',
              title: 'Usuarios',
              type: 'item',
              icon: 'users',
              url: 'usuarios/usuarios'
            }); */
            menuPermissionCoord.push({
              id: 'roles',
              title: 'Roles',
              type: 'item',
              icon: 'users',
              url: 'usuarios/roles'
            });
            menuPermissionCoord.push({
              id: 'usuarios_admin',
              title: 'Usuarios admin',
              type: 'item',
              icon: 'users',
              url: 'usuarios/admin'
            });
          this.menuP = menuPermissionCoord
        break;
        case 'Promotor':
          console.log('SOY UN Promotor');
          let menuPermissionPromo = [];
          // let permissionPromo = null;
            menuPermissionPromo.push({
              id: 'dashboard',
              title: 'Dashboard',
              type: 'item',
              icon: 'trending-up',
              url: '/dashboard'
            });
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver inmobiliarias');
          if (permission) {
            menuPermission.push({
              id: 'inmobiliarias',
              title: 'Inmobiliarias',
              type: 'item',
              icon: 'book-open',
              url: '/inmobiliarias'
            });
          } */
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver inmuebles');
          if (permission) {
            menuPermission.push({
              id: 'inmuebles',
              title: 'Inmuebles',
              type: 'item',
              icon: 'home',
              url: '/properties'
            });
          } */
            /* menuPermissionPromo.push({
              id: 'asesores',
              title: 'Asesores',
              type: 'item',
              icon: 'briefcase',
              url: 'asesores/'
            }); */
          let childPromo = []
            childPromo.push({
              id: 'leads',
              title: 'Clientes',
              type: 'item',
              icon: 'circle',
              url: '/clientes/clients'
            });
            childPromo.push({
              id: 'leads',
              title: 'Leads',
              type: 'item',
              icon: 'circle',
              url: '/clientes/leads'
            });
            childPromo = [];
            childPromo.push({
            id: 'ganancias',
            title: 'Ganancias',
            type: 'item',
            icon: 'circle',
            url: '/finanzas/ganancias'
          });
          if(childPromo.length != 0) {
            menuPermissionPromo.push({
              id: 'finanzas',
              title: 'Finanzas',
              type: 'collapsible',
              icon: 'dollar-sign',
              children: childPromo
            });
          }
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver estadísticas de ventas');
          if (permission) {
            child.push({
              id: 'statistics',
              title: 'Estadísticas',
              type: 'item',
              icon: 'circle',
              url: '/clientes/statistics'
            });
          }
          permission = this.currentUser.permissions.find(permi => permi.name === 'Ver administración interna');
          if (permission) {
            child.push({
              id: 'adminInternal',
              title: 'Administración Interna',
              type: 'item',
              icon: 'circle',
              url: '/clientes/adminInternal'
            });
          } */
          /*
          if (child.length != 0) {
            menuPermission.push({
              id: 'clientes',
              title: 'Clientes',
              type: 'collapsible',
              icon: 'smile',
              children: child
            });
          } */

          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Editar suscripción inmobiliaria');
          if (permission) {
            menuPermission.push({
              id: 'subscription',
              title: 'Suscripciones',
              type: 'item',
              icon: 'dollar-sign',
              url: '/subscriptions'
            });
          } */
          /* // permission = this.currentUser.permissions.find(permi => permi.name === 'Editar suscripción inmobiliaria');
          if (this.currentUser.role == 'Super Administrador') {
            menuPermission.push({
              id: 'products',
              title: 'Productos',
              type: 'item',
              icon: 'dollar-sign',
              url: '/products'
            });
          } */
            /* menuPermission.push({
              id: 'payments',
              title: 'Pagos Inscripción',
              type: 'item',
              icon: 'dollar-sign',
              url: '/payments'
            }); */
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver notificaciones');
          if (permission) {
            menuPermission.push({
              id: 'notification',
              title: 'Notificaciones',
              type: 'item',
              icon: 'bell',
              url: '/notifications'
            });
          } */
          childPromo = [];
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Agregar cuentas de redes sociales');
          if (permission) {
            child.push({
              id: 'redes',
              title: 'Redes Sociales',
              type: 'item',
              icon: 'circle',
              url: 'marketing/redessociales'
            });
          } */
            /* child.push({
              id: 'publicity',
              title: 'Replicas',
              type: 'item',
              icon: 'circle',
              url: 'marketing/publicidad'
            }) */
          /* if (child.length != 0) {
            menuPermission.push({
              id: 'marketing',
              title: 'Marketing',
              type: 'collapsible',
              icon: 'shopping-bag',
              children: child
            });
          } */

          /* child = [];
          permission = this.currentUser.permissions.find(permi => permi.name === 'Realizar valoración inmueble');
          if (permission) {
            child.push({
              id: 'valuacion',
              title: 'Valuación',
              type: 'item',
              icon: 'circle',
              url: 'utilidades/valoracioninmueble'
            });
          } */
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver directorio');
          if (permission) {
            child.push({
              id: 'directorio',
              title: 'Directorio',
              type: 'item',
              icon: 'circle',
              url: '/directorio'
            });
          } */
          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Realizar valoración hipoteca');
          if (permission) {
            child.push({
              id: 'hipoteca',
              title: 'Hipoteca',
              type: 'item',
              icon: 'circle',
              url: 'utilidades/valoracionhipoteca'
            });
          } */

          /* if (child.length != 0) {
            menuPermission.push({
              id: 'utilidades',
              title: 'Utilidades',
              type: 'collapsible',
              icon: 'box',
              children: child
            });
          } */

            /* let childCoord = [
              {
                id: 'faqs',
                title: 'FAQs',
                type: 'item',
                icon: 'help-circle',
                url: '/settings/faqs'
              }];
            menuPermission.push({
              id: 'settings',
              title: 'Configuraciones',
              type: 'collapsible',
              icon: 'settings',
              children: child
            }); */

          /* permission = this.currentUser.permissions.find(permi => permi.name === 'Ver asociaciones');
          if (permission) {
            menuPermission.push({
              id: 'asociaciones',
              title: 'Asociaciones',
              type: 'item',
              icon: 'book',
              url: '/asociaciones'
            });
          } */
          let childUser = [];
          childUser.push({
            id: 'miPerfil',
            title: 'Cuenta',
            type: 'item',
            icon: 'circle',
            url: 'usuarios/perfil'
          });

          menuPermissionPromo.push({
            id: 'perfil',
            title: 'Perfil',
            type: 'collapsible',
            icon: 'user',
            children: childUser
          });
            /* menuPermission.push({
              id: 'usuarios',
              title: 'Usuarios',
              type: 'item',
              icon: 'users',
              url: 'usuarios/usuarios'
            }); */
            /* menuPermission.push({
              id: 'roles',
              title: 'Roles',
              type: 'item',
              icon: 'users',
              url: 'usuarios/roles'
            });
            menuPermission.push({
              id: 'usuarios_admin',
              title: 'Usuarios admin',
              type: 'item',
              icon: 'users',
              url: 'usuarios/admin'
            }); */
          this.menuP = menuPermissionPromo
        break;

      }

      return this.menuP;
    }

  }

}
