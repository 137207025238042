import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation, LOCALE_ID } from '@angular/core';
import { User } from 'app/auth/models';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { ServerService } from '../../../../modules/admin/services/server.service';
import { PusherService } from '../../../../modules/admin/services/pusher.service';

import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { Notification } from '../../../../modules/admin/notification/notification.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Property } from '../../../../modules/admin/properties/property.model';
import Swal from 'sweetalert2';
registerLocaleData(localeEs, 'es');
@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es'
    }
  ],
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: any[] = [];
  currentUser: User;
  pusher: any;
  unreadCount: number = 0;
  propertyAct: Property;

  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _notificationsService: NotificationsService,
    private _pusher: PusherService,
    private _server: ServerService,
    private modalService: NgbModal

  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')).user;
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this._notificationsService.getOneSignal().then(
      () => {
        this._notificationsService.onApiDataChange.subscribe(res => {
          this.notifications = res;

          const data = {
            userId: this.currentUser.id,
            onesignal_id: localStorage.getItem('onesignal_id'),
          };

          this._server.postServer('setOnesignalId', data).then(
            (resp: any) => {
              this._calculateUnreadCount();

            }, (err: any) => {
              console.log("postOneSignal incorrecto");
            }
          );

          //Hay que sacar el usuario logueado para que el user.id.toString() funcione.
          this.pusher = this._pusher.getPusher();
          const channel = this.pusher.subscribe(this.currentUser.id.toString());

          channel.bind('message', (data) => { //    Recibe un nuevo mensaje
            if (data.isnotificacion) {
              const newNot: Notification = data.notificacion;
              this.notifications.unshift(newNot);
              this._calculateUnreadCount();//Numero para saber cuantas notificaciones no se han leido.
            }
            data.isnotificacion = false;
          });
        });
      }
    );


  }

  private _calculateUnreadCount(): void {
    let count = 0;

    if (this.notifications && this.notifications.length) {
      count = this.notifications.filter(notification => notification.read === 0).length;
    }

    this.unreadCount = count;
  }

  markAllAsRead(): void {

    this._server.getServer('allReadNotifications').then(
      (resp: any) => {
        this.notifications.forEach((element) => {
          element.read = 1;
        });

        this._calculateUnreadCount();
      }, (err: any) => {
        console.log("read all incorrecto");
      }
    );
  }

  // modal Open Form
  markNoti(modalForm, noti: Notification) {

    this._server.getServer('readNotification/' + noti.id).then(
      (resp: any) => {

        this.notifications.forEach((element) => {
          if (element.id === noti.id) {
            element.read = 1;
          }
        })

        this._calculateUnreadCount();

      }, (err: any) => {
        console.log("read incorrecto");
      }
    );

    if (noti.property_id) {
      this.propertyAct = noti.property;

      this.modalService.open(modalForm, {
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      });

    }


  }

  propertyNotiWhats() {
    window.open("https://wa.me/" + this.propertyAct.phoneOwner, "_blank");
  }

  propertyNotiAvilable() {

    const datas = {
      propertyId: this.propertyAct.id,
    };



    this._server.postServer('addDaysProperty', datas).then(
      (resp: any) => {
        this.notifications = this.notifications.filter(x => x.property && x.property.id !== this.propertyAct.id);
        this._calculateUnreadCount();
        this.modalService.dismissAll();
        Swal.fire({
          title: 'Aumento exitoso',
          text: 'Se ha aumentado correctamente los días de vigencia de la propiedad',
          icon: 'success'
        })
      }, (error: any) => {
        Swal.fire({
          title: 'Error',
          text: 'No se pudo aumentar los días de vigencia',
          icon: 'error'
        })
      }
    );

  }

  propertyNotiDisable() {
    this._server.getServer('disableProperty/' + this.propertyAct.id).then(
      (resp: any) => {
        this._calculateUnreadCount();
        this.modalService.dismissAll();
        Swal.fire({
          title: 'Acción exitosa',
          text: 'Se ha deshabilitado la propiedad exitosamente',
          icon: 'success'
        })
      }, (error: any) => {
        Swal.fire({
          title: 'Error',
          text: 'No se ha podido deshabilitar la propiedad',
          icon: 'error'
        })
      }
    );
  }

}
