import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";

import "hammerjs";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr"; // For auth after login toast

import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";

import { coreConfig } from "app/app-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { SampleModule } from "app/main/sample/sample.module";
import { ToastContainerComponent } from "app/modules/admin/toastr/toast-container/toast-container.component";
import { AuthGuard } from "./guards/auth.guard";
import { PusherService } from "./modules/admin/services/pusher.service";
import { CurrencyCommasHundredsPipe } from "./modules/admin/currency-commas-hundreds.pipe";
import { PaymentsListComponent } from "./modules/admin/payments/payments-list/payments-list.component";
import { PaymentsCreateComponent } from "./modules/admin/payments/payments-create/payments-create.component";
import { DatePipe } from "@angular/common";
import { SharePropertyListComponent } from "./modules/admin/shareProperty/share-property-list/share-property-list.component";
import { ComplementsListComponent } from "./modules/admin/complements/complements-list/complements-list.component";
import { ComplementsCreateComponent } from "./modules/admin/complements/complements-create/complements-create.component";
import { GananciasComponent } from "./modules/admin/finanzas/ganancias/ganancias.component";
import { CustomCurrencyPipe } from "@core/pipes/custom-currency.pipe";
// import { SeeCoincidencesComponent } from './landing/see-coincidences/see-coincidences.component';

const appRoutes: Routes = [
  // {
  //   path: 'pages',
  //   loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  // },
  {
    path: "login",
    loadChildren: () =>
      import("./main/pages/authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "inmobiliarias",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./modules/admin/inmobiliarias/inmobiliarias.module").then(
        (m) => m.InmobiliariasModule
      ),
  },
  {
    path: "share-properties",
    loadChildren: () =>
      import("./modules/admin/shareProperty/share-property.module").then(
        (m) => m.SharePropertyModule
      ),
  },
  {
    path: "asesores",
    loadChildren: () =>
      import("./modules/admin/agentes/agentes.module").then(
        (m) => m.AgentesModule
      ),
  },
  {
    path: "directorio",
    loadChildren: () =>
      import("./modules/admin/directory/directory.module").then(
        (m) => m.DirectoryModule
      ),
  },
  {
    path: "asociaciones",
    loadChildren: () =>
      import("./modules/admin/partnerships/partnership.module").then(
        (m) => m.PartnershipModule
      ),
  },
  {
    path: "clientes",
    loadChildren: () =>
      import("./modules/admin/clients/clients.module").then(
        (m) => m.ClientsModule
      ),
  },
  {
    path: "propietarios",
    loadChildren: () =>
      import("./modules/admin/owners/owner.module").then((m) => m.OwnerModule),
  },
  {
    path: "utilidades",
    loadChildren: () =>
      import("./modules/admin/utilidades/utilidades.module").then(
        (m) => m.UtilidadesModule
      ),
  },
  {
    path: "usuarios",
    loadChildren: () =>
      import("./modules/admin/users/users.module").then((m) => m.UsersModule),
  },
  {
    path: "roles",
    // loadChildren: () => import('./modules/admin/userPanel/userPanel.module').then(m => m.UserRolesModule)
    loadChildren: () =>
      import("./modules/admin/users/users.module").then((m) => m.UsersModule),
  },
  {
    path: "marketing",
    loadChildren: () =>
      import("./modules/admin/marketing/marketing.module").then(
        (m) => m.MarketingModule
      ),
  },
  {
    path: "subscriptions",
    loadChildren: () =>
      import("./modules/admin/subscription/subscription.module").then(
        (m) => m.SubscriptionModule
      ),
  },
  {
    path: "complements",
    loadChildren: () =>
      import("./modules/admin/complements/complement.module").then(
        (m) => m.ComplementsModule
      ),
  },
  {
    path: "products",
    loadChildren: () =>
      import("./modules/admin/products/products.module").then(
        (m) => m.ProductModule
      ),
  },
  {
    path: "payments",
    loadChildren: () =>
      import("./modules/admin/payments/payments.module").then(
        (m) => m.PaymentsModule
      ),
  },
  {
    path: "notifications",
    loadChildren: () =>
      import("./modules/admin/notification/notification.module").then(
        (m) => m.NotificationModule
      ),
  },
  {
    path: "settings/faqs",
    loadChildren: () =>
      import("./modules/admin/setting/faqs/faqs.module").then(
        (m) => m.FaqsModule
      ),
  },
  {
    path: "properties",
    loadChildren: () =>
      import("./modules/admin/properties/property.module").then(
        (m) => m.PropertyModule
      ),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./modules/admin/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "manual-subscriptions",
    loadChildren: () =>
      import(
        "./modules/admin/manual-subscription/manual-subscription.module"
      ).then((m) => m.ManualSubscriptionModule),
  },
  {
    path: "finanzas",
    loadChildren: () =>
      import("./modules/admin/finanzas/finanzas.module").then(
        (m) => m.FinanzasModule
      ),
  },
  {
    path: "",
    loadChildren: () =>
      import("./main/pages/landing/landing.module").then(
        (m) => m.LandingModule
      ),
  },
  {
    path: "**",
    redirectTo: "", //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [AppComponent, ToastContainerComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled", // Add options right here
      relativeLinkResolution: "legacy",
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,
  ],

  bootstrap: [AppComponent],

  providers: [PusherService, DatePipe, CustomCurrencyPipe],
})
export class AppModule {}
