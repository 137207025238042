import { Injectable } from '@angular/core';
import { ServerService } from '../services/server.service';
import { User } from 'app/auth/models';
import { Role } from './role.model';
import { Permission } from './permission.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private users: User[];
  private roles: Role[];
  private permissions: Permission[];

  constructor(
    private http: HttpClient,
    private _server: ServerService) { }

  getUsers(): Promise<User[]> {
    return new Promise((resolve, reject) => {
      this.allUsers().then(
        () => {
          const users: User[] = JSON.parse(localStorage.getItem('users'));
          resolve(users);
        }, () => {
          const users: User[] = [];
          reject(users);
        }
      );
    });
  }

  allUsers(): Promise<User[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer('Users').then(
        (resp: any) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  async getUser(id: number): Promise<User> {
    this.users = JSON.parse(localStorage.getItem('users'));
    const userUpdate: User = this.users.find(user => user.id === id);
    return userUpdate;
  }

  getRoles(): Promise<Role[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer('Roles').then(
        (resp: any) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
      /* this.allRoles().then(
        () => {
          const roles: Role[] = JSON.parse(localStorage.getItem('roles'));
          resolve(roles);
        }, () => {
          const roles: Role[] = [];
          reject(roles);
        }
      ); */
    });
  }

  storeRole(rol): Promise<Role[]> {
    return new Promise((resolve, reject) => {
      this._server.postServer('Roles', rol).then(
        (resp: any) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  deleteRole(id): Promise<Role[]> {
    return new Promise((resolve, reject) => {
      /* this.http.delete(environment.API_URL + 'Roles', id, { headers: this.header }).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        }
      ); */
      this._server.deleteServer('Roles/' + id).then(
        (resp: any) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  allRoles(): Promise<Role[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer('roles').then(
        (resp: any) => {
          this.roles = resp;
          localStorage.setItem('roles', JSON.stringify(resp));
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  allPermissions(): Promise<Permission[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer('getPermissions').then(
        (resp: Permission[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  async getRole(id: number): Promise<Role> {
    this.roles = JSON.parse(localStorage.getItem('roles'));
    const roleUpdate: Role = this.roles.find(role => role.id === id);
    return roleUpdate;
  }

  saveUser(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      this._server.postServer('Users', user).then(
        (resp: User) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  showUser(id: number): Promise<User> {
    return new Promise((resolve, reject) => {
      this._server.getServer('Users/' + id).then(
        (resp: User) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  updateUser(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      this._server.putServer('Users/' + user.id, user).then(
        (resp: User) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  deleteUser(id: Number): Promise<User> {
    return new Promise((resolve, reject) => {
      this._server.deleteServer('Users/' + id).then(
        (resp: User) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );

    });
  }
  deleteUser2(id: Number): Promise<User> {
    return new Promise((resolve, reject) => {
      this._server.getServer('remove-user/' + id).then(
        (resp: User) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  enabledDisabledUser(id: Number): Promise<User> {
    return new Promise((resolve, reject) => {
      this._server.getServer('userEnableDisable/' + id).then(
        (resp: User) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  saveAvatar(avatar: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const input = new FormData();
      input.append('avatar', avatar, avatar.name);
      this._server.uploadFile('avatarUpdate', input).then(
        (resp: any) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  saveLogo(logo: File, id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const input = new FormData();
      input.append('logo', logo, logo.name);
      input.append('id', id.toString());
      this._server.postServer('logoUpdate', input).then(
        (resp: any) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  saveUs(us: File, id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const input = new FormData();
      input.append('us', us, us.name);
      input.append('id', id.toString());
      this._server.postServer('usUpdate', input).then(
        (resp: any) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  saveImageAgent(image: File, id: number, type: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const input = new FormData();
      input.append('file', image, image.name);
      input.append('id', id.toString());
      input.append('tipo', type.toString());
      console.log('FORMDATA SERVICIO saveImageAgent()', input);
      input.forEach((value,key) => {
        console.log(key+" "+value)
      });
      this._server.postServer('imageUpdateAgent', input).then(
        (resp: any) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  saveImageAgentOtras(image: File, id: number, type: number, sort: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const input = new FormData();
      input.append('file', image, image.name);
      input.append('id', id.toString());
      input.append('tipo', type.toString());
      input.append('sort', sort.toString());
      this._server.postServer('imageUpdateAgent', input).then(
        (resp: any) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  getProfile(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer('getProfile').then(
        (resp: any) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  getRealEstate(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer('getRealEstate').then(
        (resp: any) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  getWebSite(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer('getWebPage').then(
        (resp: any) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  getAgent(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer('getAgent').then(
        (resp: any) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  updateProfile(user: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer('updateProfile', user).then(
        (resp: any) => {

          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  updateRealEstate(user: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer('updateRealEstate', user).then(
        (resp: any) => {

          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  updateWebPage(user: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer('updateWebPage', user).then(
        (resp: any) => {

          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  updateAgent(user: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer('updateAgent', user).then(
        (resp: any) => {

          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  deleteImageAgent(img: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this._server.postServer('imageDeleteAgent/' + img.id, img).then(
        (resp: any) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  realEstateSubscriptions(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer('getSubscriptionsRealEstate/' + id).then(
        (resp: any[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  ordenImages(imgs: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer('AgentImagesOrder', imgs).then(
        (resp: any) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  resetPassword(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer('resetPassword', data).then(
        (resp: any) => {
          resolve(resp);
        }, (error: any) => {
          reject(error);
        }
      );
    });
  }

  getAdmins(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer('UsersAdmins').then(
        (resp: any[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  getAdmin(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.getServer('UsersAdmins/'+id).then(
        (resp: any[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  storeNewUserAdmin(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer('UsersAdmins', data).then(
        (resp: any[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }
  updateUserAdmin(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.putServer('UsersAdmins/' + data.id, data).then(
        (resp: any[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  disabledUserAdmin(id, data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._server.postServer('disabledUserAdmin/' + id, data).then(
        (resp: any[]) => {
          resolve(resp);
        }, (err: any) => {
          reject(err);
        }
      );
    });
  }

  getStates(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._server.getServer('states').then(
        (resp: any) => {
          resolve(resp);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  

}
