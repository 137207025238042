<!-- ngb-toast component -->
<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.classname"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hide)="toastService.remove(toast)"
>
  <!-- toast header -->
  <ng-template ngbToastHeader>
    <img src="assets/images/logo/logoMuvin.ico" class="mr-2" width="18px" alt="Toast image" />
    <strong class="mr-auto toast-title">{{ toast.title }}</strong>
    <!-- <small class="text-muted toast-time ml-5 mr-1">{{ time }}</small>  -->
    <small class="text-muted toast-time ml-5 mr-1">--</small>
  </ng-template>
  <!--/ toast header -->

  <!-- toast body -->
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>
  <ng-template #text>{{ toast.textOrTpl }}</ng-template>
  <!--/ toast body -->
</ngb-toast>
<!--/ ngb-toast component -->
